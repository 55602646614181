import * as React from 'react'
import { Router } from 'next/router'
import { analytics } from './index'

export function useAnalyticsPageTracking(router: Router) {
  return React.useEffect(() => {
    const handleRouteChange = (url: any) => {
      analytics.page({
        url,
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])
}
