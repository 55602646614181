import { ClerkThemeOptions, Theme } from '@clerk/types'
import * as Colors from '@themes/Colors'
import Fonts from '@themes/Fonts'

export const clerkThemeOptions: ClerkThemeOptions = {
  general: {
    fontFamily: Fonts.type.base,
    borderRadius: '8px',
    boxShadow: 'none',
    color: Colors.primary,
    backgroundColor: Colors.backgroundGray,
    fontColor: Colors.abbyGray,
    padding: '1px',
  },
  buttons: {
    fontFamily: Fonts.type.bold,
  },
  accounts: {
    backgroundColor: Colors.white,
  },
}

export const clerkAppearanceOptions: Theme = {
  variables: {
    fontFamily: Fonts.type.base,
    borderRadius: '8px',
    fontFamilyButtons: Fonts.type.bold,
    colorPrimary: Colors.primary,
    colorBackground: Colors.backgroundGray,
    colorText: Colors.abbyGray,
    fontSize: '18px',
    // spacingUnit: '1rem',
  },
  elements: {
    rootBox: {
      display: 'flex',
      flexGrow: 1,
      flexShrink: 0,
      // flexBasis: '100%',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center',
    },
    formFieldLabel: {
      fontWeight: '600',
      // fontFamily: '"Sofia Pro", SofiaPro-Bold, sofia-pro; sans-serif',
    },
    formFieldInput: {
      padding: '1rem',
    },
    formFieldInput__phoneNumber: {
      paddingLeft: '5rem',
    },
    logoBox: {
      alignContent: 'center',
    },
    card: {
      boxShadow: 'none',

      backgroundColor: 'transparent',
    },
  },
  layout: {
    logoPlacement: 'inside',

    // logoImageUrl: '/images/logo.png',
    // helpPageUrl: '/help',
    // privacyPageUrl: '/privacy',
    // termsPageUrl: '/terms',

    socialButtonsPlacement: 'bottom',
    socialButtonsVariant: 'iconButton',
    showOptionalFields: false,
  },
}
