import * as React from 'react'
import { ActivityIndicator } from 'react-native'
import { styled, theme } from '@themes/stitches.config'
import type { VariantProps, ComponentProps } from '@stitches/react'

const ButtonInternal = styled('button', {
  all: 'unset',
  borderWidth: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',

  userSelect: 'none',
  cursor: 'pointer',

  textTransform: 'uppercase',

  fontFamily: '$bold',
  fontWeight: '700',

  textAlign: 'center',

  '&:disabled': {
    cursor: 'not-allowed',
  },

  '&:focus': {
    outline: '$sky4 4px solid',
    outlineOffset: -2,
  },

  variants: {
    format: {
      rectangular: {
        borderRadius: 4,
      },
      rounded: {
        borderRadius: 32,
      },
    },

    emphasis: {
      primary: {
        borderColor: '$sky10',
        color: '$white',

        backgroundColor: '$primary',

        '&:hover': {
          backgroundColor: '$sky10',
        },
        '&:active': {
          backgroundColor: '$primary',
        },
        '&:disabled': {
          backgroundColor: '$sky9',
        },
      },
      secondary: {
        backgroundColor: '$neutralGray',
        borderColor: '$neutralGray',
        color: '$black',
        '&:hover': {
          backgroundColor: '$sky10',
        },
        '&:active': {
          backgroundColor: '$primary',
        },
        '&:disabled': {
          backgroundColor: '$sky9',
        },
      },
      tertiary: {
        backgroundColor: 'transparent',
        borderColor: '$primary',
        color: '$primary',
        '&:hover': {
          color: '$primary',
        },
        '&:active': {
          color: '$primary',
        },
        '&:disabled': {
          backgroundColor: '$sky9',
        },
      },
      green: {
        borderColor: '$sky10',
        color: '$white',

        backgroundColor: '$green',

        '&:hover': {
          backgroundColor: '#49da83',
        },
        '&:active': {
          backgroundColor: '$green',
        },

        '&:focus': {
          outline: '$slate4 4px solid',
          outlineOffset: -2,
        },

        '&:disabled': {
          color: '$slate10',
          backgroundColor: '$slate6',
        },
      },
    },

    size: {
      small: {
        fontSize: '$small',
        letterSpacing: 1,
        paddingBlock: 10,
        paddingInline: 12,
        lineHeight: 1.5,
      },
      regular: {
        fontSize: '$regular',

        lineHeight: '20px',
        letterSpacing: 1,
        paddingBlock: 16,
        paddingInline: 24,
      },
    },

    fullWidth: {
      true: {
        width: '100%',
      },
    },

    fullFlex: {
      true: {
        flex: 1,
      },
    },

    spaced: {
      true: {
        letterSpacing: 1.75,
      },
    },
  },

  defaultVariants: {
    format: 'rectangular',
    emphasis: 'primary',
    size: 'regular',
    fullWidth: false,
    fullFlex: false,
  },
})

type ButtonProps = {
  title: string
  isBusy?: boolean
  disabled?: boolean
} & VariantProps<typeof ButtonInternal> &
  ComponentProps<typeof ButtonInternal>

export function Button(props: ButtonProps) {
  const { title, isBusy, disabled, ...restProps } = props
  return (
    <ButtonInternal aria-label={title} {...restProps} disabled={disabled}>
      {isBusy ? (
        <ActivityIndicator
          size="small"
          color={theme.colors.altWhite.toString()}
        />
      ) : (
        title
      )}
    </ButtonInternal>
  )
}
