import * as React from 'react'
import { useClerk } from '@clerk/nextjs'
import { Client, CloseCode, createClient } from 'graphql-ws'
import { HASURA_NEXT_JWT } from '@purposity/auth'
import { wsEndpoint } from '@config/AppConfig'
import { useFlags } from '@features/flags/context'

interface WSClientContextValues {
  subscribe: Client['subscribe']
  dispose: Client['dispose']
  on: Client['on']
}

const WSClientContext = React.createContext<WSClientContextValues>(null)
WSClientContext.displayName = 'WSClient'

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function WsClientProvider({ children }) {
  const authProvider = useWsClientProvider()
  React.useDebugValue(authProvider)
  return (
    <WSClientContext.Provider value={authProvider}>
      {children}
    </WSClientContext.Provider>
  )
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useWsClient = () => {
  return React.useContext(WSClientContext)
}

// Provider hook that creates auth object and handles state
function useWsClientProvider() {
  const [client, setClient] = React.useState<Client | undefined>(undefined)
  const [shouldRefreshToken, setShouldRefreshToken] = React.useState(false)
  const tokenExpiryTimeout = React.useRef<NodeJS.Timeout | undefined>(undefined)

  const clerk = useClerk()

  const {
    state: { enableWebsocket },
  } = useFlags()
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any component that utilizes this hook to re-render with the latest auth object.
  React.useEffect(() => {
    if (enableWebsocket) {
      const instance = createClient({
        url: wsEndpoint,
        connectionParams: async () => {
          if (shouldRefreshToken) {
            await clerk.session?.getToken({
              template: HASURA_NEXT_JWT,
              skipCache: true,
            })

            setShouldRefreshToken(false)
          }
          const tokenResult = await clerk.session?.getToken({
            template: HASURA_NEXT_JWT,
          })

          if (tokenResult) {
            return {
              headers: {
                Authorization: `Bearer ${tokenResult}`,
              },
            }
          }
          return {}
        },

        on: {
          connected: async (socket) => {
            if (isWebSocket(socket)) {
              // clear timeout on every connect for debouncing the expiry
              clearTimeout(tokenExpiryTimeout.current)

              // set a token expiry timeout for closing the socket
              // with an `4403: Forbidden` close event indicating
              // that the token expired. the `closed` event listner below
              // will set the token refresh flag to true
              const currentTokenExpiresIn = await getTtl()
              tokenExpiryTimeout.current = setTimeout(() => {
                if (socket.readyState === WebSocket.OPEN)
                  socket.close(CloseCode.Forbidden, 'Forbidden')
              }, currentTokenExpiresIn)
            }
          },
          closed: (event) => {
            // if closed with the `4403: Forbidden` close event
            // the client or the server is communicating that the token
            // is no longer valid and should be therefore refreshed
            if (isCloseEvent(event)) {
              if (event.code === CloseCode.Forbidden) {
                setShouldRefreshToken(true)
              }
            }
          },
        },
      })
      setClient(instance)

      return () => {
        instance.dispose()
        setClient(undefined)
      }
    }
    return () => {
      // do nothing
    }
  }, [clerk.session, enableWebsocket, shouldRefreshToken])

  return {
    subscribe: client?.subscribe,
    dispose: client?.dispose,
    on: client?.on,
  }
}

function isWebSocket(socket: WebSocket | unknown): socket is WebSocket {
  return (socket as WebSocket).OPEN !== undefined
}

const getTtl = async () => {
  // TODO: parse Clerk token and calculate TTL from expiry
  return 1000 * 30
}

function isCloseEvent(event: CloseEvent | unknown): event is CloseEvent {
  return (event as CloseEvent) instanceof CloseEvent
}
