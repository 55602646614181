import {
  AnalyticsInstanceAny,
  CreateTrackPayload,
  IdentifyPayload,
} from 'analytics'
import { dateSchema } from 'types/misc'
import * as z from 'zod'
import { PhoneNumber } from '@purposity/utils'
import { getHash } from '@purposity/utils/edge'
import { MyAnalyticsInstance } from '..'
import { PluginsCreator } from '../analytics.types.plugins'

declare global {
  // eslint-disable-next-line no-var
  // var fbq: (...args: any[]) => void
  type PixelId = Brand<string, 'pixelId'>
}

type PluginConfig<
  TAnalyticsInstance extends AnalyticsInstanceAny = AnalyticsInstanceAny
> = {
  pixelId: string
  autoConfig?: boolean
  /** @example __fbAm */
  advancedMatchingStorageKey: string
  formatIdentify: (
    identifyPayload: IdentifyPayload<
      TAnalyticsInstance['_shape']['traits'],
      TAnalyticsInstance['_shape']['identifyOptions']
    >
  ) => AdvancedMatchingInput
  formatTrack: (
    trackPayload: CreateTrackPayload<
      TAnalyticsInstance['_shape']['eventMap'],
      TAnalyticsInstance['_shape']['trackOptions']
    >
  ) => EventProperties
}

type CreateFacebookPixelPlugin<
  TAnalyticsInstance extends AnalyticsInstanceAny
> = PluginsCreator.Create<PluginConfig<TAnalyticsInstance>, TAnalyticsInstance>

let IS_INITIALIZED = false
const REGEX_CACHE = new Map<string, RegExp>()

const plugin: CreateFacebookPixelPlugin<MyAnalyticsInstance> = (
  pluginConfig
) => {
  /** The following settings may be configurable at some point, but for now are hard-coded here */
  const defaultSettings = {
    advancedMatchingStorageKey: '__fbAm',
  }

  return {
    name: 'facebookPixel',
    config: pluginConfig,

    initialize: ({ config }) => {
      const { pixelId, ...options } = config

      if (typeof window === 'undefined') return
      if (IS_INITIALIZED) return
      if (typeof pixelId !== 'string' || pixelId.trim().length === 0)
        throw new TypeError('pixelId should be a non empty "string"')

      if (typeof options !== 'object')
        throw new TypeError('options should be of type "object"')

      // prettier-ignore
      /* eslint-disable */
      //#region Facebook Pixel Code
      const v = (function(f:any,b:any,e: any,v:any,n?:any,t?:any,s?:any)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)})(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      //#endregion Facebook Pixel Code
      /* eslint-enable */

      const fbq = window.fbq
      if (options.autoConfig === false) {
        fbq('set', 'autoConfig', false, pixelId)
      }
      fbq('init', pixelId)
      fbq('track', 'PageView')
      IS_INITIALIZED = true
    },

    page: (_bucket) => {
      if (typeof window !== 'undefined' && IS_INITIALIZED && 'fbq' in window) {
        fbq('track', 'PageView')
      }
    },

    track({ payload, instance, config }) {
      if (typeof window !== 'undefined' && IS_INITIALIZED && 'fbq' in window) {
        const amUser = instance.storage.getItem<AdvancedMatching>(
          defaultSettings.advancedMatchingStorageKey
        )

        trackCustom(
          payload.event,
          {
            external_id: payload.userId || payload.anonymousId,
            formatted: config.formatTrack(payload),
            ...amUser,
          },
          {
            ...(payload.options?.eventId && {
              eventId: payload.options?.eventId,
            }),
          }
        )
      }
    },

    identify: async ({ payload, instance, config }) => {
      if (typeof window !== 'undefined' && IS_INITIALIZED && 'fbq' in window) {
        const amUser = await AdvancedMatchingPartial.strip().parseAsync(
          config.formatIdentify(payload)
        )

        instance.storage.setItem<AdvancedMatchingInput>(
          defaultSettings.advancedMatchingStorageKey,
          amUser
        )
      }
    },

    loaded: () => {
      if (typeof window !== 'undefined' && IS_INITIALIZED && 'fbq' in window) {
        return scriptLoaded(pluginConfig.pixelId)
      } else {
        return false
      }
    },

    reset: async (bucket) => {
      if (typeof window !== 'undefined') {
        const { instance, config } = bucket
        if (IS_INITIALIZED) {
          instance.storage.removeItem(
            defaultSettings.advancedMatchingStorageKey
          )

          window.fbq('init', config.pixelId)
        }
      }
    },
  }
}

function scriptLoaded(pixelId: PluginConfig['pixelId']) {
  if (!REGEX_CACHE.has(pixelId)) {
    const re = new RegExp('connect.facebook.net/en_US/fbevents.js')
    REGEX_CACHE.set(pixelId, re)
  }

  const scripts = document?.querySelectorAll('script[src]')
  if (!scripts) return false

  for (const script of scripts.values()) {
    if (script.getAttribute('src')?.match(REGEX_CACHE.get(pixelId)!))
      return true
  }

  return false
}

export { plugin as fbPixelPlugin }

const AdvancedMatching = z.object({
  /** Email
   * @description Unhashed lowercase or hashed SHA-256
   * @example jsmith@example.com or 6e3913852f512d76acff15d1e402c7502a5bbe6101745a7120a2a4833ebd2350
   */
  em: z
    .string()
    .email()
    .transform((val) => val.toLowerCase())
    .transform((val) => getHash(val)),

  /** First Name
   * @description Lowercase letters
   * @example john
   */
  fn: z.string().transform((val) => val.toLowerCase()),

  /** Last Name
   * @description Lowercase letters
   * @example smith
   */
  ln: z.string().transform((val) => val.toLowerCase()),

  /** Phone
   * @description Digits only including country code and area code
   * @example 16505554444
   */
  ph: z
    .number()
    .int()
    .min(1_000_000_0000)
    .max(1_999_999_9999)
    .or(PhoneNumber.transform((val) => Number(val))),

  /** External ID
   * @description Any unique ID from the advertiser, such as loyalty membership ID, user ID, and external cookie ID.
   * @example a@example.com
   */
  external_id: z.string(),

  /** Gender
   * @description Single lowercase letter, f or m, if unknown, leave blank
   * @example f
   */
  ge: z.enum(['f', 'm']).or(z.undefined()),

  /** Birthdate
   * @description Digits only with birth year, month, then day
   * @example 19910526 for May 26, 1991.
   */
  db: dateSchema.transform((val) =>
    val.toISOString().split('T')[0].replace(/-/g, '')
  ),

  /** City
   * @description Lowercase with any spaces removed
   * @example menlopark
   */
  ct: z.string().transform((val) => val.toLowerCase().replace(/\s/g, '')),

  /** State or Province
   * @description Lowercase two-letter state or province code
   * @example ca
   */
  st: z.string().length(2),

  /** Zip or Postal Code
   * @description String
   * @example 94025
   */
  zp: z.string().length(5),

  /** Country
   * @description Lowercase two-letter country code
   * @example us
   */
  country: z
    .string()
    .length(2)
    .transform((val) => val.toLowerCase()),
})
type AdvancedMatching = z.infer<typeof AdvancedMatching>

export const AdvancedMatchingPartial = AdvancedMatching.deepPartial()
export type AdvancedMatchingInput = z.input<typeof AdvancedMatchingPartial>

/** @see https://github.com/sempostma/fbq */
export enum FacebookEventType {
  'AddPaymentInfo' = 'ADD_PAYMENT_INFO',
  'AddToCart' = 'ADD_TO_CART',
  'AddToWishlist' = 'ADD_TO_WISHLIST',
  'CompleteRegistration' = 'COMPLETE_REGISTRATION',
  'Contact' = 'CONTACT',
  'CustomizeProduct' = 'CUSTOMIZE_PRODUCT',
  'Donate' = 'DONATE',
  'FindLocation' = 'FIND_LOCATION',
  'InitiateCheckout' = 'INITIATE_CHECKOUT',
  'Lead' = 'LEAD',
  'Purchase' = 'PURCHASE',
  'Schedule' = 'SCHEDULE',
  'Search' = 'SEARCH',
  'StartTrial' = 'START_TRIAL',
  'SubmitApplication' = 'SUBMIT_APPLICATION',
  'Subscribe' = 'SUBSCRIBE',
  'ViewContent' = 'VIEW_CONTENT',
  'PageView' = 'PAGE_VIEW',
}
export const FacebookEventTypes = z.nativeEnum(FacebookEventType)

function trackCustom(
  eventName: string,
  eventProperties: any,
  eventMetadata?: any
) {
  return fbq('trackCustom', eventName, eventProperties, eventMetadata)
}

type EventProperties = {
  // external_id: '<USER_ID>'
  external_id?: string
  /**
   * custom_event_type: '<CORRESPONDING_STANDARD_EVENT>',
   * @see `Promoted Object` column of table on: https://developers.facebook.com/docs/meta-pixel/reference
   */
  custom_event_type?: FacebookEventType
  /** content_ids: [params.id], */
  content_ids?: string[]
  /** content_name: params.name, */
  content_name?: string
  /** content_category: '', */
  content_category?: string
  /** content_type: object, */
  content_type?: 'object'
  /** float */
  /** value: params.value as number, (float) */
  value?: number
  /** currency: 'USD', */
  currency?: 'USD'
  [key: string]: any
}
