/// <reference path="./analytics.d.ts" />

import gtagPlugin from '@analytics/google-analytics'
import { PURPOSITY_ENV } from '@constants'
import { env } from '@env'
import { setUser } from '@sentry/nextjs'
import createAnalytics, { AnalyticsInstance } from 'analytics'
import originalSrc from 'analytics-plugin-original-source'
import currency from 'currency.js'
import { eventValidationPlugin } from './plugins/event-validation'
import { FacebookEventType, fbPixelPlugin } from './plugins/facebook'
import { sentryPlugin } from './plugins/sentry-plugin'
// purposity specific config
import * as PIdentify from './purposity-config/identify'
import * as PPage from './purposity-config/pages'
import * as PTrack from './purposity-config/track'

export type MyAnalyticsInstance = AnalyticsInstance<
  PIdentify.Traits,
  PTrack.EventMap,
  PTrack.Options,
  PPage.PageDataCustom,
  PPage.Options,
  PIdentify.Options
>

export const analytics = createAnalytics<MyAnalyticsInstance>({
  app: 'purposity',
  version:
    typeof __NEXT_DATA__ !== 'undefined' ? __NEXT_DATA__.buildId : undefined,
  debug: true,
  plugins: [
    originalSrc(),
    eventValidationPlugin({
      eventMap: PTrack.EventMap,
      debug: true,
    }),

    sentryPlugin({
      debug: true,
      setUser,
      formatUser: (identifyPayload) => {
        if (identifyPayload.userId) {
          const email =
            identifyPayload.traits.primary_email_address || undefined
          const username = identifyPayload.traits.clerk_id || undefined

          return {
            id: identifyPayload.userId || identifyPayload.anonymousId,
            ...(email && { email }),
            ...(username && { username }),
          }
        } else {
          return null
        }
      },
    }),

    gtagPlugin({
      measurementIds: [
        env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
        env.NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE,
      ],
      debug: true,
    }),

    fbPixelPlugin({
      formatIdentify: (identifyPayload) => ({
        external_id: identifyPayload.userId!,
        fn: identifyPayload.traits.first_name,
        ln: identifyPayload.traits.last_name,
        em: identifyPayload.traits.primary_email_address,
        ph: identifyPayload.traits.primary_phone_number,
        ct: identifyPayload.traits.city,
        st: identifyPayload.traits.state,
        country: identifyPayload.traits.country || 'US',
      }),
      formatTrack: (trackPayload) => {
        switch (trackPayload.event) {
          case 'user_signup':
            return {
              custom_event_type: FacebookEventType.CompleteRegistration,
              status: true,
            }

          case 'org_follow':
          case 'org_unfollow':
            return {
              content_id: `org_${trackPayload.properties.id}`,
            }
          case 'team_join':
          case 'team_leave':
            return {
              content_id: `team_${trackPayload.properties.id}`,
            }
          case 'checkout_create':
            return {
              custom_event_type: FacebookEventType.InitiateCheckout,
              contents: trackPayload.properties.line_items?.map((item) => ({
                ...item,
                quantity: 1,
              })),
              currency: 'USD',
              num_items: trackPayload.properties.summary?.count,
              value: currency(trackPayload.properties.summary?.sum ?? 0, {
                fromCents: true,
              }).value,
            }

          case 'checkout_complete':
            return {
              custom_event_type: FacebookEventType.Purchase,
            }

          case 'goal_set':
            return {}
          default: {
            const _exhaustiveCheck: never = trackPayload.event
            return _exhaustiveCheck
          }
        }
      },
      advancedMatchingStorageKey: '__fbAm',
      pixelId: env.NEXT_PUBLIC_FB_PIXEL_ID!,
      autoConfig: false,
      enabled: PURPOSITY_ENV === 'production' || PURPOSITY_ENV === 'preview',
      debug: true,
    }),
  ],
})
