import 'raf/polyfill'
import 'setimmediate'

import * as React from 'react'
import {
  Hydrate as ReactQueryHydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import Head from 'next/head'
import { ClerkProvider } from '@clerk/nextjs'
import { Analytics } from '@vercel/analytics/react'
import { trpc } from 'utils/trpc'
import { Favicon } from '@components/Favicon'
import { GlobalSeo } from '@components/SEO/FallbackSeo'
import { cdnUrlBase, clerkBaseUrl, hasuraApiUrlBase } from '@config/AppConfig'
import { DevTools } from '@features/dev-tools'
import { KEY } from '@features/dev-tools/constants'
import { useEnabled } from '@features/dev-tools/hooks/useEnabled'
import { FlagProvider } from '@features/flags/context'
import { RoadblockModal } from '@features/roadblock/components/RoadblockModal'
import { RoadblockProvider } from '@features/roadblock/hooks/useRoadblock'
import { ClerkRefresh } from '@lib/Auth/clerk-refresh'
import ReactNativeNextScrollRestore from '@lib/global-setup/react-native-next-scroll-restore'
import { defaultOptions, useQueryClientPersist } from '@lib/react-query'
import { DefaultDataProvider } from '@lib/react-query/data-packages/default'
import { useShortcuts } from '@lib/utils/useShortcuts'
import { useAnalyticsPageTracking } from '@services/Analytics/useAnalyticsPageTracking'
import { ToastsPortal } from '@services/Toasts'
import { WsClientProvider } from '@services/websocket/useWsClient'
import { clerkThemeOptions } from '@themes/ClerkThemeOptions'
import type { AppPropsWithLayout } from 'types/next'
import '../styles/FontAwesome.css'

ReactNativeNextScrollRestore.initialize()

function App({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout<{ dehydratedState?: any }>) {
  useShortcuts()
  useAnalyticsPageTracking(router)

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <FlagProvider>
      <ClerkProvider
        {...pageProps}
        navigate={(to) => router.push(to)}
        theme={clerkThemeOptions}
        frontendApi={clerkBaseUrl.host}
        supportEmail="hello@purposity.com">
        <InnerProvider>
          <ReactQueryHydrate state={pageProps.dehydratedState}>
            {getLayout(<Component {...pageProps} />)}
          </ReactQueryHydrate>
        </InnerProvider>
      </ClerkProvider>
    </FlagProvider>
  )
}

function InnerProvider(props: { children: React.ReactNode }) {
  const enabled = useEnabled(KEY)

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions,
      })
  )

  useQueryClientPersist(queryClient)

  return (
    <DefaultDataProvider>
      <QueryClientProvider client={queryClient}>
        <RoadblockProvider>
          <WsClientProvider>
            <>{props.children}</>

            <Analytics />
            <Favicon />

            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <GlobalSeo />
              <link
                key="cdn-preconnect"
                rel="preconnect"
                href={cdnUrlBase.href}
              />
              <link
                key="cdn-prefetch"
                rel="dns-prefetch"
                href={cdnUrlBase.href}
              />
              <link
                key="api-preconnect"
                rel="preconnect"
                href={hasuraApiUrlBase.href}
              />
              <link
                key="api-prefetch"
                rel="dns-prefetch"
                href={hasuraApiUrlBase.href}
              />
              <link
                key="auth-preconnect"
                rel="preconnect"
                href={clerkBaseUrl.href}
              />
              <link
                key="auth-prefetch"
                rel="dns-prefetch"
                href={clerkBaseUrl.href}
              />
            </Head>

            <ClerkRefresh />
            <ToastsPortal />
            <RoadblockModal />

            {enabled && <DevTools />}
          </WsClientProvider>
        </RoadblockProvider>
      </QueryClientProvider>
    </DefaultDataProvider>
  )
}

export default trpc.withTRPC(App)
