const type = {
  base: `Avenir-Book, 'Avenir Book', sans-serif`,
  bold: `'SofiaPro-Bold', sofia-pro`,
  emphasis: 'HelveticaNeue-Italic',
  alternateBold: `SourceSansPro-SemiBold, 'Source Sans Pro', system-ui, sans-serif`,
  sourceBold: `SourceSansPro-Bold, 'Source Sans Pro', system-ui, sans-serif`,
  regular: `SourceSansPro-Regular, 'Source Sans Pro', system-ui, sans-serif`,
  sofia: `SofiaProRegular, sofia-pro`,
  gilroy: 'Gilroy-Regular, Gilroy, system-ui, sans-serif',
  gilroyBold: 'Gilroy-Bold, GilroyBold, Gilroy, system-ui, sans-serif',
}

const typeWithWeight = {
  base: {
    fontFamily: type.base,
    fontWeight: 'normal',
  },
  bold: {
    fontFamily: type.bold,
    fontWeight: 'bold',
  },
  emphasis: {
    fontFamily: type.emphasis,
    fontWeight: 'normal',
  },
  alternateBold: {
    fontFamily: type.alternateBold,
    fontWeight: 'bold',
  },
  sourceBold: {
    fontFamily: type.sourceBold,
    fontWeight: 'bold',
  },
  regular: {
    fontFamily: type.regular,
    fontWeight: 'normal',
  },
  sofia: {
    fontFamily: type.sofia,
    fontWeight: 'bold',
  },
  gilroy: {
    fontFamily: type.gilroy,
    fontWeight: 'normal',
  },
  gilroyBold: {
    fontFamily: type.gilroyBold,
    fontWeight: 'bold',
  },
}

const size = {
  h1: 38,
  h2: 34,
  h3: 30,
  h4: 26,
  h5: 22,
  h6: 20,
  input: 18,
  regular: 16,
  regium: 15,
  medium: 13,
  small: 12,
  tiny: 9,
}

const style = {
  h1: {
    fontFamily: type.base,
    fontSize: size.h1,
  },
  h2: {
    fontFamily: type.bold,
    fontWeight: 'bold',
    fontSize: size.h2,
  },
  h3: {
    fontFamily: type.emphasis,
    fontSize: size.h3,
  },
  h4: {
    fontFamily: type.base,
    fontSize: size.h4,
  },
  h5: {
    fontFamily: type.base,
    fontSize: size.h5,
  },
  h6: {
    fontFamily: type.emphasis,
    fontSize: size.h6,
  },
  normal: {
    fontFamily: type.base,
    fontSize: size.regular,
  },
  description: {
    fontFamily: type.base,
    fontSize: size.medium,
  },
}

export const Fonts = {
  type,
  typeWithWeight,
  size,
  style,
}

export default Fonts
