import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { stringify } from 'querystring'
import ms from 'ms'
import * as z from 'zod'
import { deviceQK } from '@lib/global-setup/deviceQK'
import { GeoInput, Geo } from './types'

const GeoResponse = z.object({
  geo: Geo.nullable(),
})

export function useGeo(initial?: Partial<GeoInput>) {
  const router = useRouter()

  const initialParsed = Geo.partial().parse(initial || {}, {
    path: ['useGeo', 'initial'],
  })
  const initialCombined = useMemo(() => {
    const searchParams = new URLSearchParams(stringify(router.query))
    return {
      geo: {
        latitude: initialParsed.latitude ?? searchParams.get('latitude'),
        longitude: initialParsed.longitude ?? searchParams.get('longitude'),
        city: initialParsed.city ?? searchParams.get('city'),
        region: initialParsed.region ?? searchParams.get('region'),
        country: initialParsed.country ?? searchParams.get('country'),
      },
      updatedAt: Date.now(),
    }
  }, [
    initialParsed.city,
    initialParsed.country,
    initialParsed.latitude,
    initialParsed.longitude,
    initialParsed.region,
    router.query,
  ])

  const primedData = Geo.safeParse(initialCombined.geo)

  return useQuery(
    deviceQK.detail('geo'),
    async () => {
      const getGeo = await fetch('/api/geo', {
        method: 'POST',
        headers: { accept: 'application/json' },
        credentials: 'include',
      })
      if (getGeo.headers.get('content-type')?.includes('application/json')) {
        const json = await getGeo.json()
        const valid = await GeoResponse.parseAsync(json)
        return valid.geo
      } else {
        return null
      }
    },
    {
      select: (data) => data,
      retry: false,
      staleTime: ms('1h'),
      initialData: primedData.success ? primedData.data : undefined,
      initialDataUpdatedAt: primedData.success
        ? initialCombined.updatedAt
        : undefined,
      onError(err) {
        console.groupCollapsed('useGeo')
        console.warn(err)
        console.groupEnd()
      },
    }
  )
}
