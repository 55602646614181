import * as z from 'zod'

export const Environment = z.enum([
  'development',
  'preview',
  'production',
  'test',
  'local',
])

export const PURPOSITY_ENV = Environment.parse(
  process.env.VERCEL_ENV ||
    process.env.NEXT_PUBLIC_VERCEL_ENV ||
    (process.env.NODE_ENV === 'production' ? 'local' : process.env.NODE_ENV) ||
    'development'
)

export const WEBHOOK_SECRET_HEADER_KEY = 'x-hasura-webhook-secret'

export const PERMANENT_CLIENT_COOKIE_NAME = '__perm_client'
export const PERMANENT_USER_COOKIE_NAME = '__perm_user'

export const SENTRY_DSN =
  'https://f334a8cde6bd48b68082520c4bc718d5@o546790.ingest.sentry.io/6060573'
