import ms from 'ms'
import { dateSchema, jsonSchema } from 'types/misc'
import * as z from 'zod'

export const DEFAULT_EXPIRATION_TIME = ms('10 min')
export const ERRORS = z.enum([
  'expired',
  'storage_access_error',
  'null_value',
  'invalid_json',
])

const ExpiringValue = z
  .object({
    value: z.any(),
    expiration: dateSchema.describe('expiration'),
  })
  .refine((val) => Date.now() < val.expiration.getTime(), {
    message: ERRORS.enum.expired,
    path: ['expiration'],
  })

const JSONtoObject = z
  .string({
    errorMap: (_issue, ctx) => {
      if (typeof ctx.data === 'undefined')
        return {
          message: ERRORS.enum.storage_access_error,
        }

      if (ctx.data === null)
        return {
          message: ERRORS.enum.null_value,
        }

      return { message: ctx.defaultError }
    },
    description: 'storage_value',
  })
  .transform((val, ctx) => {
    try {
      return JSON.parse(val)
    } catch (error) {
      ctx.addIssue({
        code: 'invalid_type',
        expected: 'object',
        received: typeof val,
        fatal: true,
        message: ERRORS.enum.invalid_json,
      })
    }
  })

const toStorageValue = (value: any, expiration: number) =>
  jsonSchema
    .transform((val) => {
      return JSON.stringify({
        value: val,
        expiration: Date.now() + expiration,
      })
    })
    .parseAsync(value)

export async function getValue(key: string) {
  if (typeof window !== 'undefined') {
    try {
      const item = localStorage.getItem(key)
      if (item === null) return undefined

      const obj = await JSONtoObject.parseAsync(item)
      const parsed = await ExpiringValue.parseAsync(obj)

      return parsed.value
    } catch (error) {
      if (error instanceof z.ZodError) {
        // isNullish
        if (
          error.errors.some((issue) => issue.message === ERRORS.enum.null_value)
        ) {
          return null
        }

        console.warn(error)

        // isExpired
        if (
          error.errors.some((issue) => issue.message === ERRORS.enum.expired)
        ) {
          localStorage.removeItem(key)
        }
      }
    }
  }
}

export async function setValue<TValue extends any>(
  key: string,
  value: TValue,
  expiration: number = DEFAULT_EXPIRATION_TIME
) {
  if (typeof window !== 'undefined') {
    if (value === null) {
      return localStorage.removeItem(key)
    }

    return localStorage.setItem(key, await toStorageValue(value, expiration))
  }
}
