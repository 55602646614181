import { useRouter } from 'next/router'
import { useHotkeys } from 'react-hotkeys-hook'
import { LOGIN_PATH, SIGNUP_PATH } from '@purposity/auth'
import { KEY } from '@features/dev-tools/constants'

export function useShortcuts() {
  const router = useRouter()

  useHotkeys('g+i', () => {
    router.push({ pathname: '/' })
  })

  useHotkeys('g+h', () => {
    router.push({ pathname: '/home' })
  })

  useHotkeys('g+l', () => {
    router.push({ pathname: LOGIN_PATH })
  })

  useHotkeys('g+c', () => {
    router.push({ pathname: '/checkout' })
  })

  useHotkeys('g+p', () => {
    router.push({ pathname: '/profile' })
  })

  useHotkeys('g+s', () => {
    router.push({ pathname: SIGNUP_PATH })
  })

  useHotkeys('g+t', () => {
    router.push({ pathname: '/teams' })
  })

  useHotkeys('g+n', () => {
    router.push({ pathname: '/home' })
  })

  useHotkeys('g+m', () => {
    router.push({ pathname: '/need/59412' })
  })
  useHotkeys('g+w', () => {
    router.push({ pathname: '/onboarding' })
  })

  useHotkeys('g+o', () => {
    router.push({ pathname: '/organizations' })
  })

  useHotkeys('g+b', () => {
    router.push({ pathname: '/blog' })
  })

  useHotkeys('d+t', () => {
    window?.localStorage.setItem(`${KEY}`, 'true')
    window?.location.reload()
  })

  useHotkeys('d+f', () => {
    window?.localStorage.setItem(`${KEY}`, 'false')
    window?.location.reload()
  })

  useHotkeys('cmd+k+l', () => {
    window.location.href = window?.location.href.replace(
      window.location.origin,
      'http://localhost:3000'
    )
  })

  useHotkeys('d+l', () => {
    window.location.href = window?.location.href.replace(
      window.location.origin,
      'http://localhost:3000'
    )
  })
}
