import * as React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

type Props = {
  height?: number | string
  width?: number | string
  backgroundColor?: string
  noShrink?: boolean
  style?: StyleProp<ViewStyle>
}

export function Spacer({
  height,
  width,
  backgroundColor,
  noShrink,
  style,
}: Props) {
  return (
    <View
      data-testid="spacer"
      accessibilityRole="none"
      style={[
        styles.base,
        { height, width, backgroundColor },
        noShrink && styles.noShrink,
        style,
      ]}
    />
  )
}

const styles = StyleSheet.create({
  base: {
    flexShrink: 1,
    flexGrow: 0,
    height: 1,
    width: 1,
    backgroundColor: 'rgba(0,0,0,0)',
  },
  noShrink: {
    flexShrink: 0,
  },
})
