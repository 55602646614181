import React, {
  ReactNode,
  createContext,
  useReducer,
  useContext,
  useEffect,
  useMemo,
} from 'react'

const KEY = '@purposity/flags'

export enum Flag {
  testAuth = 'testAuth',
  blueDev = 'blueDev',
  allowPhone = 'allowPhone',
  showHiddenFormFields = 'showHiddenFormFields',
  enableWebsocket = 'enableWebsocket',
  showReactQueryDevTools = 'showReactQueryDevTools',
  enableNewAdminFeatures = 'enableNewAdminFeatures',
  enableHasuraCookieAuth = 'enableHasuraCookieAuth',
}

type State = Record<Flag, boolean>

const DEFAULT_STATE: State = {
  testAuth: false,
  blueDev: true,
  allowPhone: true,
  showHiddenFormFields: false,
  enableWebsocket: false,
  showReactQueryDevTools: true,
  enableNewAdminFeatures: false,
  enableHasuraCookieAuth: true,
}

type Action = { target: Flag; type: 'true' | 'false' | 'toggle' }

type Dispatch = (action: Action) => void
type FlagProviderProps = { children: ReactNode }

const FlagStateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function flagReducer(state: State, action: Action) {
  switch (action.type) {
    case 'true': {
      return {
        ...state,
        [action.target]: true,
      }
    }
    case 'false': {
      return {
        ...state,
        [action.target]: true,
      }
    }
    case 'toggle': {
      return {
        ...state,
        [action.target]: !state[action.target],
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const getInitialState = (): State => {
  if (typeof window === 'undefined') {
    return DEFAULT_STATE
  }
  const savedItem: string | undefined | null = window?.localStorage.getItem(KEY)
  return savedItem ? JSON.parse(savedItem) : DEFAULT_STATE
}

function FlagProvider({ children }: FlagProviderProps) {
  const [state, dispatch] = useReducer(flagReducer, getInitialState())
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  useEffect(() => {
    function isEqual(a: State, b: State) {
      for (const key in a) {
        if (a[key] !== b[key]) {
          return false
        }
      }
      return true
    }

    if (!isEqual(value.state, DEFAULT_STATE)) {
      window?.localStorage.setItem(KEY, JSON.stringify(value.state))
    } else {
      window?.localStorage.removeItem(KEY)
    }
  }, [value])

  return (
    <FlagStateContext.Provider value={value}>
      {children}
    </FlagStateContext.Provider>
  )
}

function useFlags() {
  const context = useContext(FlagStateContext)
  if (context === undefined) {
    throw new Error('useFlags must be used within a FlagsProvider')
  }
  return context
}

export { FlagProvider, useFlags }
