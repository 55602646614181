import * as z from 'zod'

/**
 * degreees north or south of the Equator
 * @example 38.895
 */

export const Latitude = z
  .number()
  .or(z.string())
  .transform((value) => Number(value))
  .refine((arg) => arg > -90 && arg < 90, 'Latitude must be between -90 and 90')
/**
 * degrees east or west of the Prime Meridian
 * @example -77.036
 */

export const Longitude = z
  .number()
  .or(z.string())
  .transform((value) => Number(value))
  .refine(
    (arg) => arg > -180 && arg < 180,
    'Longitude must be between -180 and 180'
  )
export const Geo = z.object({
  latitude: Latitude,
  longitude: Longitude,
  city: z.string().optional(),
  country: z.string().optional(),
  region: z.string().optional(),
})
export type GeoInput = z.input<typeof Geo>
export type GeoOutput = z.output<typeof Geo>
