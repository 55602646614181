type Literal = boolean | null | number | string
type Json = Literal | { [key: string]: Json } | Json[]

export const deviceQK = {
  all: [{ scope: 'device' }] as const,
  lists: () => [{ ...deviceQK.all[0], area: 'list' }] as const,
  list: (filters: Json) => [{ ...deviceQK.lists()[0], filters }] as const,
  details: () => [{ ...deviceQK.all[0], area: 'detail' }] as const,
  detail: (id: string) => [{ ...deviceQK.details()[0], id }] as const,
}
