import * as React from 'react'
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient as useQueryClientOriginal,
} from '@tanstack/react-query'

export const context = React.createContext<QueryClient | undefined>(undefined)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
    mutations: {},
  },
})

export function useQueryClient() {
  return useQueryClientOriginal({
    context,
  })
}

// TODO: useQuery, useMutation, useInfiniteQuery, usePaginatedQuery, etc.

export function DefaultDataProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <QueryClientProvider client={queryClient} context={context}>
      {children}
    </QueryClientProvider>
  )
}
