import { useEffect } from 'react'
import { DefaultOptions, QueryClient, QueryKey } from '@tanstack/react-query'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { PURPOSITY_ENV } from '@constants'

export type MutationContext<TSnapshot = any> = {
  snapshot?: TSnapshot
}

export type SnapshotDetail<
  TQueryKey extends QueryKey,
  TSnapshotData extends any
> = {
  key: TQueryKey
  data?: TSnapshotData
}

export const defaultOptions: DefaultOptions = {
  queries: {
    networkMode: 'offlineFirst',
    retry: PURPOSITY_ENV === 'development' ? false : 3,
  },
  mutations: {
    networkMode: 'offlineFirst',
  },
}

export function useQueryClientPersist(queryClient: QueryClient) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (PURPOSITY_ENV !== 'development') {
        const persister = createSyncStoragePersister({
          storage: window.localStorage,
          key: '@purposity/query-cache',
        })

        const keyVersion = 2

        const cacheKey: readonly [
          keyVersion: number,
          busterType: 'build' | 'date',
          busterValue: string
        ] = __NEXT_DATA__
          ? [keyVersion, 'build', __NEXT_DATA__.buildId]
          : [keyVersion, 'date', new Date().toLocaleDateString()]

        persistQueryClient({
          buster: JSON.stringify(cacheKey),
          queryClient,
          persister,
        })
      }
    }
  }, [queryClient])
}
