import * as React from 'react'
import ReactFavicon from 'react-favicon'
import { PURPOSITY_ENV } from '@constants'
import { getEnvColors } from '@lib/utils/get-env-colors'

export function Favicon() {
  return PURPOSITY_ENV === 'production' ? (
    <ReactFavicon
      url="/favicon.png"
      // renderOverlay={invertIcon()}
      iconSize={64}
      animated={false}
      keepIconLink={() => true}
    />
  ) : (
    <ReactFavicon
      url="/favicon.png"
      // renderOverlay={invertIcon()}
      iconSize={64}
      animated={false}
      keepIconLink={() => true}
      alertCount={PURPOSITY_ENV.slice(0, 1).toUpperCase()}
      alertFillColor={getEnvColors(PURPOSITY_ENV).fillColor}
      alertTextColor={getEnvColors(PURPOSITY_ENV).textColor}
    />
  )
}
