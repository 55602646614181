import { useState, useEffect, useDebugValue } from 'react'
import Cookies from 'cookies-ts'
import { makeLog } from '@purposity/utils'
import { PURPOSITY_ENV } from '@constants'
import { KEY } from '../constants'

const cookies = new Cookies()

export function useEnabled(key: string = KEY, defaultValue?: boolean) {
  const [enabled, setEnabled] = useState(defaultValue ?? false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const fromCookie = cookies.get(key)
      const fromSearch = new URLSearchParams(window?.location.search).get(key)
      const fromLocalStorage = window?.localStorage.getItem(key)

      const explicitlyDisabled =
        fromCookie === 'false' ||
        fromSearch === 'false' ||
        fromLocalStorage === 'false'

      const explicitlyEnabled =
        fromCookie === 'true' ||
        fromSearch === 'true' ||
        fromLocalStorage === 'true'

      makeLog('enabled').log(key, {
        fromCookie,
        fromSearch,
        fromLocalStorage,
        explicitlyDisabled,
        explicitlyEnabled,
      })

      if (
        !explicitlyDisabled &&
        (PURPOSITY_ENV === 'development' || explicitlyEnabled)
      ) {
        setEnabled(true)
      }
    }
  }, [key])
  useDebugValue({
    enabled,
    key,
  })
  return enabled
}
