import { Environment } from '@constants'

export function getEnvColors(env: Environment): {
  fillColor?: string
  textColor?: string
} {
  switch (env) {
    case 'production':
      return {
        fillColor: undefined,
        textColor: undefined,
      }
    case 'preview':
      return {
        fillColor: '#ffdf01',
        textColor: '#000000',
      }
    case 'development':
      return {
        fillColor: '#dc2727',
        textColor: '#ffffff',
      }
    case 'local':
      return {
        fillColor: '#f1f3f5',
        textColor: '#5746af',
      }
    case 'test':
      return {
        fillColor: '#ed5f00',
        textColor: '#000000',
      }
    default: {
      const _exhaustiveCheck: never = env
      return _exhaustiveCheck
    }
  }
}
