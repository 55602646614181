/* eslint-disable no-process-env */
import * as z from 'zod'
import { looseHrefSchema } from '@purposity/utils/edge'

const requireInProduction = (/** @type {unknown} */ v) => {
  const isStringMin1 = typeof v === 'string' && v.length > 0
  if (process.env.VERCEL_ENV === 'production') {
    return isStringMin1
  } else {
    return true
  }
}

// Server-side schema
const server = z.object({
  NODE_ENV: z.enum(['development', 'test', 'production']),
  /** @deprecated */
  ABOUT_SITE_ORIGIN: looseHrefSchema,
  CONNECT_SITE_ORIGIN: looseHrefSchema,
  ADMIN_API_SECRET: z.string().min(1),
  ADMIN_SITE_API_TOKEN: z.string().min(1),
  ADMIN_SITE_ORIGIN: looseHrefSchema,
  API_ORIGIN: looseHrefSchema,
  LEGACY_API_PROXY_ORIGIN: looseHrefSchema.optional(),
  CLERK_API_KEY: z.string().min(1),
  CLERK_JWT_KEY: z.string().min(1),
  DOPPLER_CONFIG: z.string().min(1),
  DOPPLER_ENVIRONMENT: z.enum(['dev', 'preview', 'prod']),
  DOPPLER_PROJECT: z.string().min(1),
  GA_MEASUREMENT_ID: z.string().min(1).optional().refine(requireInProduction),
  GA_MEASUREMENT_ID_ALTERNATE: z.string().min(1),
  HASURA_WEBHOOK_SECRET: z.string().min(1),
  SECRET_KEY: z.string().min(1),
  SENTRY_AUTH_TOKEN: z.string().optional().refine(requireInProduction),
  SENTRY_ORG: z.string().optional().refine(requireInProduction),
  SENTRY_PROJECT: z.string().optional().refine(requireInProduction),
  STRIPE_SECRET_KEY: z.string().min(1),
  UPSTASH_REST_API_DOMAIN: looseHrefSchema,
  UPSTASH_REST_API_TOKEN: z.string().min(1),
  UPSTASH_REST_API_TOKEN_READONLY: z.string().min(1),
  VERCEL_ENV: z
    .enum(['development', 'preview', 'production'])
    .default('preview'),
  VERCEL_URL: looseHrefSchema.optional(),
})

// Client-side schema
const client = z.object({
  NEXT_PUBLIC_API_ORIGIN: looseHrefSchema,
  NEXT_PUBLIC_CDN_ORIGIN: looseHrefSchema,
  NEXT_PUBLIC_CLERK_FRONTEND_API: looseHrefSchema.transform(
    (v) => new URL(v).hostname
  ),
  NEXT_PUBLIC_FB_PIXEL_ID: z.string().optional().refine(requireInProduction),
  NEXT_PUBLIC_GA_MEASUREMENT_ID: z.string().min(1),
  NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE: z.string().min(1),
  NEXT_PUBLIC_LOGFLARE_API_KEY: z.string().min(1),
  NEXT_PUBLIC_LOGFLARE_SOURCE_KEY: z.string().min(1),
  NEXT_PUBLIC_ONESIGNAL_APP_ID: z.string().min(1),
  NEXT_PUBLIC_SANITY_DATASET: z.string().min(1),
  NEXT_PUBLIC_SANITY_PROJECT_ID: z.string().min(1),
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional().refine(requireInProduction),
  NEXT_PUBLIC_VERCEL_ENV: z
    .enum(['development', 'preview', 'production'])
    .default('preview'),
  NEXT_PUBLIC_VERCEL_URL: looseHrefSchema.optional(),
})

/**
 * `process.env` cannot be spread in next's client or edge runtimes so it must be manually destructured
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  NODE_ENV: process.env.NODE_ENV,
  ABOUT_SITE_ORIGIN: process.env.ABOUT_SITE_ORIGIN,
  CONNECT_SITE_ORIGIN: process.env.CONNECT_SITE_ORIGIN,
  ADMIN_API_SECRET: process.env.ADMIN_API_SECRET,
  ADMIN_SITE_API_TOKEN: process.env.ADMIN_SITE_API_TOKEN,
  ADMIN_SITE_ORIGIN: process.env.ADMIN_SITE_ORIGIN,
  API_ORIGIN: process.env.API_ORIGIN,
  LEGACY_API_PROXY_ORIGIN: process.env.LEGACY_API_PROXY_ORIGIN,
  CLERK_API_KEY: process.env.CLERK_API_KEY,
  CLERK_JWT_KEY: process.env.CLERK_JWT_KEY,
  DOPPLER_CONFIG: process.env.DOPPLER_CONFIG,
  DOPPLER_ENVIRONMENT: process.env.DOPPLER_ENVIRONMENT,
  DOPPLER_PROJECT: process.env.DOPPLER_PROJECT,
  GA_MEASUREMENT_ID: process.env.GA_MEASUREMENT_ID,
  GA_MEASUREMENT_ID_ALTERNATE: process.env.GA_MEASUREMENT_ID_ALTERNATE,
  HASURA_WEBHOOK_SECRET: process.env.HASURA_WEBHOOK_SECRET,
  SECRET_KEY: process.env.SECRET_KEY,
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
  SENTRY_ORG: process.env.SENTRY_ORG,
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  SENTRY_PROJECT: process.env.SENTRY_PROJECT,
  UPSTASH_REST_API_DOMAIN: process.env.UPSTASH_REST_API_DOMAIN,
  UPSTASH_REST_API_TOKEN: process.env.UPSTASH_REST_API_TOKEN,
  UPSTASH_REST_API_TOKEN_READONLY: process.env.UPSTASH_REST_API_TOKEN_READONLY,
  VERCEL_ENV: process.env.VERCEL_ENV,
  VERCEL_URL: process.env.VERCEL_URL,
  NEXT_PUBLIC_API_ORIGIN: process.env.NEXT_PUBLIC_API_ORIGIN,
  NEXT_PUBLIC_CDN_ORIGIN: process.env.NEXT_PUBLIC_CDN_ORIGIN,
  NEXT_PUBLIC_CLERK_FRONTEND_API: process.env.NEXT_PUBLIC_CLERK_FRONTEND_API,
  NEXT_PUBLIC_FB_PIXEL_ID: process.env.NEXT_PUBLIC_FB_PIXEL_ID,
  NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE:
    process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID_ALTERNATE,
  NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  NEXT_PUBLIC_LOGFLARE_API_KEY: process.env.NEXT_PUBLIC_LOGFLARE_API_KEY,
  NEXT_PUBLIC_LOGFLARE_SOURCE_KEY: process.env.NEXT_PUBLIC_LOGFLARE_SOURCE_KEY,
  NEXT_PUBLIC_ONESIGNAL_APP_ID: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
  NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
  NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
}

// Don't touch the part below
// --------------------------
const merged = server.merge(client)

/** @typedef {z.input<typeof merged>} MergedInput */
/** @typedef {z.infer<typeof merged>} MergedOutput */
/** @typedef {z.SafeParseReturnType<MergedInput, MergedOutput>} MergedSafeParseReturn */

let env = /** @type {MergedOutput} */ (process.env)
// let env = process.env

if (!!process.env.SKIP_ENV_VALIDATION === false) {
  const isServer = typeof window === 'undefined'

  const parsed = /** @type {MergedSafeParseReturn} */ (
    isServer
      ? merged.safeParse(processEnv, { errorMap }) // on server we can validate all env vars
      : client.safeParse(processEnv, { errorMap }) // on client we can only validate the ones that are exposed
  )

  if (parsed.success === false) {
    console.error(
      '❌ Invalid environment variables:',
      parsed.error.flatten().fieldErrors
    )
    throw new Error('Invalid environment variables')
  }

  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== 'string') return undefined
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith('NEXT_PUBLIC_'))
        throw new Error(
          process.env.NODE_ENV === 'production'
            ? '❌ Attempted to access a server-side environment variable on the client'
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`
        )
      return target[/** @type {keyof typeof target} */ (prop)]
    },
  })
}

/**
 * @type {z.ZodErrorMap}
 */
function errorMap(issue, _ctx) {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    return {
      message: `${issue.code}: Expected '${issue.expected}', received '${issue.received}'`,
    }
  }
  return { message: _ctx.defaultError }
}

export { env }
