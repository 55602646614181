import { PURPOSITY_ENV } from '@constants'
import { env } from '@env'
import { looseHrefSchema } from '@purposity/utils/edge'

export const appName = '@purposity/native'

export const cdnUrlBase = new URL(env.NEXT_PUBLIC_CDN_ORIGIN)

export const hasuraApiUrlBase = new URL(env.NEXT_PUBLIC_API_ORIGIN)

export const deploymentUrlBase = new URL(
  PURPOSITY_ENV === 'production'
    ? `https://www.purposity.com`
    : PURPOSITY_ENV === 'preview'
    ? env.NEXT_PUBLIC_VERCEL_URL!
    : `http://localhost:${process.env.port ?? 3000}`
)

export const computedApiBase =
  typeof window === 'undefined'
    ? new URL(hasuraApiUrlBase).href
    : new URL(window.location.href).href

export const wsEndpoint = new URL(
  '/v1/graphql',
  'wss://' + hasuraApiUrlBase.host
).href

export const SUPER_ORG_ID = 27 as const

export const clerkBaseUrl = new URL(
  looseHrefSchema.parse(env.NEXT_PUBLIC_CLERK_FRONTEND_API)
)

export const onesignalAppId = env.NEXT_PUBLIC_ONESIGNAL_APP_ID

export const sanity = {
  projectId: env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: env.NEXT_PUBLIC_SANITY_DATASET,
}

export const logflare = {
  apiKey: env.NEXT_PUBLIC_LOGFLARE_API_KEY,
  apiUrl: 'https://api.logflare.app/logs/cloudflare',
  sourceKey: env.NEXT_PUBLIC_LOGFLARE_SOURCE_KEY,
}

const tipBase = {
  percentages: [0, 0.1, 0.15, 0.2],
  defaultIndex: 2,
}

export const tip = {
  ...tipBase,
  defaultPercentage: tipBase.percentages[tipBase.defaultIndex],
}
