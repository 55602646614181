import * as React from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as z from 'zod'
import { Referral, ReferralBuilder } from '@purposity/utils'
import { getValue, setValue } from './expiring-storage'

const GLOBAL_KEY = ['@purposity', 'referral']
GLOBAL_KEY.toString = () => GLOBAL_KEY.join('/')

export function useReferral() {
  const queryClient = useQueryClient()
  const setReferral = async (variables: Referral | null) => {
    if (typeof window !== 'undefined') {
      try {
        const value = variables
          ? await Referral.parseAsync(variables, {
              path: ['mutation'],
            })
          : null

        await setValue(`${GLOBAL_KEY}`, value)
      } catch (error) {
        if (error instanceof z.ZodError)
          console.warn('Setting referral failed', error)
        else throw error
      }
      return queryClient.invalidateQueries(GLOBAL_KEY)
    }
  }

  const getReferral = useQuery(
    GLOBAL_KEY,
    async () => {
      if (typeof window !== 'undefined') {
        try {
          const savedValue = await getValue(`${GLOBAL_KEY}`)
          if (!savedValue) return null
          const referral = await Referral.parseAsync(savedValue)
          return referral ? ReferralBuilder(referral) : null
        } catch (error) {
          console.warn('An error occured while parsing the referral', error)
          return null
        }
      }
      return null
    },
    {}
  )

  const referral = getReferral.data
  React.useDebugValue(referral)

  return { referral, setReferral }
}
