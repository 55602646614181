import * as React from 'react'
import Head from 'next/head'

export const FallbackSeo = () => {
  return (
    <Head>
      <title key="title">
        Purposity – A community proving humanity is good.
      </title>
      <meta
        key="description"
        name="description"
        content="A community proving humanity is good."
      />
      <meta key="og:title" property="og:title" content="Purposity" />
      <meta
        key="og:description"
        property="og:description"
        content="A community proving humanity is good."
      />
      <meta
        key="og:image"
        content="https://global-uploads.webflow.com/5def9cce41c660d30a9a5f0c/5e61401c4b1bdbee838eb90c_open-graph.jpg"
        property="og:image"
      />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
    </Head>
  )
}

export const GlobalSeo = () => {
  return (
    <>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Purposity" />
      <meta property="twitter:domain" content="purposity.com" />
      <meta property="twitter:site" content="@purposity_" />

      <link rel="shortcut icon" href="/favicon.png" type="image/x-icon" />
      <link rel="apple-touch-icon" sizes="256x256" href="/favicon.png" />

      <meta property="al:ios:app_name" content="Purposity"></meta>
      <meta property="al:ios:app_store_id" content="1439261020"></meta>
    </>
  )
}
